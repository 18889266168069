import { Grid, useTheme } from '@mui/material';
import { Draggable } from 'react-beautiful-dnd';
import { FeatureBuildingAddress, IBuildingAddress } from '@types';
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded';
import formatAddress from '@helpers/formatAddress';
import { ICON_LIGHT } from '@constants';
import BoundedText from '@components/bounded-text';
import { CustomCard } from './Building';

interface AddressProps {
  address: FeatureBuildingAddress;
  index: number;
}

const BuildingAddress = ({ address, index }: AddressProps) => {
  const { mainTitle, secondaryTitle } = formatAddress(address as unknown as IBuildingAddress);

  const theme = useTheme();

  return (
    <Draggable draggableId={`${address.id}`} index={index}>
      {(provided, snapshot) => (
        <CustomCard
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          isExpanded={false}
          sx={{
            backgroundColor: '#F9F9F9',
            '&:hover': {
              backgroundColor: '#F9F9F9'
            },
            ...(snapshot.isDragging ? { backgroundColor: theme.palette.primary[100] } : {})
          }}
        >
          <Grid container flexDirection='row' flexWrap='nowrap' alignItems='center' gap={1} mx={2}>
            <Grid item display='flex'>
              <LocationOnRoundedIcon
                sx={{
                  color: ICON_LIGHT,
                  fontSize: '24px'
                }}
              />
            </Grid>
            <Grid container item flexDirection='column'>
              <BoundedText
                initialWidth={259}
                text={mainTitle}
                textSx={{
                  color: theme.palette.neutral[900],
                  fontSize: theme.typography.pxToRem(14)
                }}
              >
                {mainTitle}
              </BoundedText>

              {/* if it's default string otherwise it can be ReactElement */}
              {typeof secondaryTitle === 'string' ? (
                <BoundedText
                  initialWidth={259}
                  text={secondaryTitle}
                  textSx={{
                    color: theme.palette.neutral[600],
                    fontSize: theme.typography.pxToRem(13)
                  }}
                >
                  {secondaryTitle}
                </BoundedText>
              ) : (
                secondaryTitle
              )}
            </Grid>
          </Grid>
        </CustomCard>
      )}
    </Draggable>
  );
};

export default BuildingAddress;
