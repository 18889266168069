/* eslint-disable eqeqeq */
/* eslint-disable max-lines-per-function */
import { Grid, IconButton, Typography, Box, styled, BoxProps, useTheme } from '@mui/material';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { FeatureBuildingAddress } from '@types';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import ApartmentRoundedIcon from '@mui/icons-material/ApartmentRounded';
import { ICON_LIGHT } from '@constants';
import BuildingAddress from './BuildingAddress';

interface BuildingProps {
  identifier: string;
  addresses: FeatureBuildingAddress[];
  index: number;
  expandedId?: string;
  setExpandedId: (id: string) => void;
  buildingIds: string[];
  setHoveredId: (id: string) => void;
  isDragging: boolean;
  previouslyExpandedId: React.MutableRefObject<string | null>;
}

interface CustomCardProps extends BoxProps {
  isExpanded: boolean;
}

export const CustomCard = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isExpanded'
})<CustomCardProps>(({ theme, isExpanded }) => ({
  backgroundColor: isExpanded ? theme.palette.neutral.landingGrey : 'white',
  '&:hover': {
    backgroundColor: theme.palette.neutral.landingGrey
  },
  border: `1px solid ${theme.palette.neutral[200]}`,
  borderRadius: '4px',
  paddingBottom: 10,
  paddingTop: 10
}));

const Building = ({
  identifier,
  addresses,
  index,
  expandedId,
  setExpandedId,
  setHoveredId,
  isDragging,
  previouslyExpandedId
}: BuildingProps) => {
  const isExpanded = expandedId === identifier;
  const isPreviouslyExpanded = previouslyExpandedId.current === identifier;
  const theme = useTheme();

  // useEffect(() => {
  //   if (isDragging) {
  //     setExpandedId((expandedId) => {
  //       if (expandedId) {
  //         previouslyExpandedId.current = expandedId;
  //         return '';
  //       }

  //       return expandedId;
  //     });
  //   }
  // }, [identifier, isDragging, previouslyExpandedId, setExpandedId, setHoveredId]);

  return (
    <Draggable draggableId={identifier.toString()} index={index}>
      {(provided, snapshot) => {
        if (snapshot.isDragging) {
          setHoveredId(identifier);
          if (expandedId == identifier) {
            setExpandedId('');
          }

          return (
            <Grid
              key={identifier.toString()}
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              style={{
                ...provided.draggableProps.style
              }}
              onMouseOver={() => {
                setHoveredId(identifier);
              }}
              onMouseLeave={() => {
                setHoveredId(null);
              }}
            >
              <CustomCard
                isExpanded={false}
                sx={{
                  backgroundColor: theme.palette.primary[100]
                }}
              >
                <Grid
                  container
                  justifyContent='space-between'
                  alignItems='center'
                  flexDirection='row'
                  flexWrap='nowrap'
                  px={2}
                >
                  <Grid container item flexDirection='row' gap={1}>
                    <Grid item display='flex' alignItems='center'>
                      <ApartmentRoundedIcon
                        sx={{
                          color: ICON_LIGHT,
                          fontSize: '24px'
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <Typography
                        sx={{
                          fontSize: '14px',
                          color: theme.palette.neutral[900]
                        }}
                      >
                        {identifier}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: '13px',
                          color: theme.palette.neutral[600]
                        }}
                      >
                        {addresses.length} adressen
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <IconButton
                      onClick={() => {
                        setExpandedId(isExpanded ? '' : identifier);
                      }}
                    >
                      {isExpanded ? <ExpandLess /> : <ExpandMore />}
                    </IconButton>
                  </Grid>
                </Grid>
              </CustomCard>
            </Grid>
          );
        }

        return (
          <Grid
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            onMouseOver={() => {
              setHoveredId(identifier);
            }}
            onMouseLeave={() => {
              setHoveredId(null);
            }}
            {...(isDragging &&
              provided.draggableProps.style?.transform &&
              !isPreviouslyExpanded && {
                style: {
                  ...provided.draggableProps.style,
                  transform: `translate(0px, ${58}px)`
                }
              })}
          >
            <CustomCard isExpanded={isExpanded}>
              <Grid
                container
                justifyContent='space-between'
                alignItems='center'
                flexDirection='row'
                flexWrap='nowrap'
                px={2}
              >
                <Grid container item flexDirection='row' gap={1}>
                  <Grid item display='flex' alignItems='center'>
                    <ApartmentRoundedIcon
                      sx={{
                        color: ICON_LIGHT,
                        fontSize: '24px'
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <Typography
                      sx={{
                        fontSize: '14px',
                        color: theme.palette.neutral[900]
                      }}
                    >
                      {identifier}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: '13px',
                        color: theme.palette.neutral[600]
                      }}
                    >
                      {addresses.length} adressen
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item>
                  <IconButton
                    onClick={() => {
                      setExpandedId(isExpanded ? '' : identifier);
                    }}
                  >
                    {isExpanded ? <ExpandLess /> : <ExpandMore />}
                  </IconButton>
                </Grid>
              </Grid>
            </CustomCard>
            {isExpanded ? (
              <Droppable droppableId={identifier.toString()} type='Address'>
                {(dropProvided) => (
                  <Grid
                    sx={{
                      display: 'flex',
                      gap: 1,
                      pt: 1,
                      flexDirection: 'column'
                    }}
                    ref={dropProvided.innerRef}
                    {...dropProvided.droppableProps}
                    id='building-addresses'
                  >
                    {addresses.map((address, buildingIndex) => (
                      <BuildingAddress key={address.id} address={address} index={buildingIndex} />
                    ))}
                    {dropProvided.placeholder}
                  </Grid>
                )}
              </Droppable>
            ) : null}
          </Grid>
        );
      }}
    </Draggable>
  );
};

export default Building;
