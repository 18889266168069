/* eslint-disable eqeqeq */
import { useEffect, useState, useRef } from 'react';
import { Grid, Typography, Box } from '@mui/material';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import { FeatureBuilding } from '@types';
import { setBuildingState, BuildingState } from '@utils/map';
import { MapSourceName } from '@utils/map/data';
import useMapContext from '@hooks/useMapContext';
import Building from './Building';

const FeatureAttachedBuildings = ({
  buildingsAttachedToFeature,
  setBuildingsAttachedToFeature
}: {
  buildingsAttachedToFeature: FeatureBuilding[] | null;
  setBuildingsAttachedToFeature: (data: FeatureBuilding[]) => void;
}) => {
  const [expandedId, setExpandedId] = useState('');
  const [hoveredId, setHoveredId] = useState(false);
  const previouslyExpandedId = useRef('');

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    if (result.type === 'Address' && result.source.droppableId === result.destination.droppableId) {
      const items = [...(buildingsAttachedToFeature as FeatureBuilding[])] as FeatureBuilding[];
      const buildingIndex = items.findIndex(
        (item) => item.id === Number(result.source.droppableId)
      );
      const [reorderedItem] = items[buildingIndex].addresses.splice(result.source.index, 1);
      items[buildingIndex].addresses.splice(result.destination.index, 0, reorderedItem);
      setBuildingsAttachedToFeature(items);
      return;
    }

    const items = [...(buildingsAttachedToFeature as FeatureBuilding[])];
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setBuildingsAttachedToFeature(items);
  };

  const { map } = useMapContext();

  const buildingIds = buildingsAttachedToFeature?.map((building) => building.id);

  useEffect(() => {
    const buildingSource = map?.getSource('buildings');
    const locationsLineDataSource = map?.getSource(MapSourceName.LocationLine);

    if (buildingSource && locationsLineDataSource) {
      const locationLineData = locationsLineDataSource?.serialize()
        .data as GeoJSON.FeatureCollection;

      const clonedLocationLineData = { ...locationLineData };
      buildingIds?.forEach((buildingId) => {
        if ([expandedId, hoveredId].includes(buildingId)) {
          setBuildingState({
            map: map as Map,
            buildingIds: buildingIds || [],
            id: `${buildingId}`,
            property: BuildingState.Select,
            state: true
          });
        } else {
          setBuildingState({
            map: map as Map,
            buildingIds: buildingIds || [],
            id: `${buildingId}`,
            property: BuildingState.Select,
            state: false
          });
        }
      });

      clonedLocationLineData.features = clonedLocationLineData.features.map((feature) => {
        if (
          (expandedId || hoveredId) &&
          ![expandedId, hoveredId].includes(feature?.properties?.buildingId)
        ) {
          return {
            ...feature,
            properties: {
              ...feature.properties,
              opacity: 0.5
            }
          };
        }
        return {
          ...feature,
          properties: {
            ...feature.properties,
            opacity: 1
          }
        };
      });

      locationsLineDataSource?.setData(clonedLocationLineData);
    }

    return () => {
      buildingIds?.forEach((buildingId) => {
        setBuildingState({
          map: map as Map,
          buildingIds: buildingIds || [],
          id: `${buildingId}`,
          property: BuildingState.Select,
          state: false
        });
      });
    };
  }, [buildingIds, map, expandedId, hoveredId]);

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Box pt={1}>
        <Typography
          pb={2}
          sx={{
            fontSize: '1rem',
            fontWeight: 500,
            color: (theme) => theme.palette.neutral.light
          }}
        >
          Gebouwen ({buildingsAttachedToFeature?.length || 0})
        </Typography>

        <Droppable droppableId='building-list'>
          {(provided, snapshot) => {
            return (
              <Grid
                ref={provided.innerRef}
                {...provided.droppableProps}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 1
                }}
              >
                {buildingsAttachedToFeature?.map(({ id: buildingIdentifier, addresses }, index) => {
                  return (
                    <Building
                      isDragging={snapshot.draggingFromThisWith}
                      key={buildingIdentifier}
                      identifier={buildingIdentifier}
                      buildingIds={buildingIds}
                      addresses={addresses}
                      previouslyExpandedId={previouslyExpandedId}
                      index={index}
                      setHoveredId={setHoveredId}
                      expandedId={expandedId}
                      setExpandedId={setExpandedId}
                    />
                  );
                })}
                <Grid
                  sx={{
                    '& > div': {
                      maxHeight: 58,
                      height: 58
                    }
                  }}
                >
                  {provided.placeholder}
                </Grid>
              </Grid>
            );
          }}
        </Droppable>
      </Box>
    </DragDropContext>
  );
};

export default FeatureAttachedBuildings;
